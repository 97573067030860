import Util from "./Util";

class ChatSocket {
    constructor(url) {
        this.onmessage = undefined;

        this.openListener = []
        this.messageListener = {}

        this.url = url;
        this.socket = new WebSocket(url);

        // Listen for messages
        this.socket.addEventListener("message", (event) => {
            console.log("Message from chat server ", event.data, this.messageListener);
            for(let key in this.messageListener) {
                if (key.length == 36) {
                    let fn = this.messageListener[key].listener;
                    fn(event.data);
                }
            }
        });

        // Connection opened
        this.socket.addEventListener("open", (event) => {
            console.log("chat connected.");

            this.openListener.forEach((listener, idx)=>{
                listener();
            });
        });
    }

    addOpenListener(listener) {
        this.openListener.push(listener)
    }

    addMessageListener(listener) {
        let item = {id: Util.guid(), listener:listener};
        this.messageListener[item.id] = item;
        return item;
    }

    removeListener(item) {
        if (item.id in this.messageListener) {
            delete this.messageListener[item.id];
        }
    }

    sendMessage(msg) {
        this.socket.send(msg);
    }
}

export default ChatSocket;
