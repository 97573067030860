import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/reset.css'
import './index.css';
import AppRouter from './Router/AppRouter.js'
import { HashRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import {UserInfo, RoomInfo} from "./LivePage/Context";
import Util from "./LivePage/Util";

function getWindowSize(){
  var w = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

  var h = window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

  return {width: w, height: h};
}

console.log(getWindowSize().width);

var scale = (getWindowSize().width / 1440);
scale = scale > 1.0 ? 1.0 : scale;
scale = scale < 0.7 ? 0.7 : scale;

// document.getElementsByTagName('body')[0].style.transform = `scale(${scale}, ${scale})`;
//document.getElementsByTagName('body')[0].style.zoom = scale;
if(scale < 1) {
  // document.getElementsByTagName('body')[0].style.width = '1440px';
}

// set id and name
UserInfo.ID = Util.getUrlParam('id');
UserInfo.NAME = Util.getUrlParam('name');
UserInfo.ROLE = Util.getUrlParam('role');

// load room info
RoomInfo.ID = Util.getUrlParam('room_id');
RoomInfo.Aid = Util.getUrlParam('aid');
RoomInfo.Vid = Util.getUrlParam('vid');

console.log(RoomInfo, UserInfo)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter>
      <AppRouter/>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
