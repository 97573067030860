import React from "react";
import { Routes, useNavigate, Route } from "react-router-dom";
import './AppRouter.css'
import Livepage from "../LivePage/LivePage";
import CameraSelection from "../Components/DeviceSelect/CameraSelection";
import LivePage_Teacher from "../LivePage/LivePage_Teacher";
import RtcPlayPage from "../LivePage/RtcPlayPage";

function AppRouter(){
    return(
        <div className="app-router-main-div" style={{height: '100%'}}>
            <Routes className='mainDiv'>
                <Route path="/" element={<Livepage/>} />
                <Route path="/DeviceSelect" element={<CameraSelection/>} />
                <Route path="/Teacher" element={<LivePage_Teacher/>} />
                <Route path="/RtcPlay" element={<RtcPlayPage/>} />
            </Routes>
        </div>
    )
}

export default AppRouter;
