// src/ChatBox.js

import React, { useState, useEffect, useRef } from 'react';
import {List, Input, Button, Layout, Tabs, Flex} from 'antd';
//import 'antd/dist/antd.css';
import './ChatRoom.css';
import Message from "./Message";
import { SendOutlined } from "@ant-design/icons";
import Link from "./Link";
import SignalSocket from "./SignalSocket";
import ChatSocket from "./ChatSocket";
import Util from "./Util";
import {UserInfo} from "./Context";
import MsgSerial, {MsgType} from "./MsgSerial";
import {fireChangeForInputTimeIfValid} from "@testing-library/user-event/dist/keyboard/shared";

const {  Footer, Content } = Layout;

const { TextArea } = Input;

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#4096ff',
    height: '64px'
};
const layoutStyle = {
    borderRadius: 8,
    overflow: 'auto',
    height: '100%',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    color: '#fff',
    backgroundColor: '#0958d9',
};

let messageCache = [];
const chatSo = new ChatSocket(Util.getChatUrl());
chatSo.addOpenListener(()=>{
    // chatSo.sendMessage(serial.serial(MsgType.Type_Info, UserInfo.ID, UserInfo.NAME, 'join room'));
});
const serial = new MsgSerial;
let sendMessageCache = '';

const ChatRoom = (props) => {
    let [updateMsg, setUpdateMsg] = useState(1);

    console.log(props, `calc(${props.si.height} - 480px)`)
    const room_main_style = {
        overflowY: 'auto',
        height: `calc(${props.si.height}px - 110px - 240px)`
    }

    useEffect(()=>{

        const item = chatSo.addMessageListener((msg)=>{
            const obj = JSON.parse(msg);
            const type = obj.t;

            if (type == MsgType.Type_Chat) {
                if (obj.si == UserInfo.ID) {
                    console.log("self msg, ignored.");
                    return;
                }

                onChatMessage(obj);
            } else if (type == MsgType.Type_ChatCount) {
                props.countFn(obj.c);
            }
        });

        return ()=>{
            chatSo.removeListener(item);
        }
    }, []);

    const sendMessage = () => {
        if(sendMessageCache.trim()) {
            const newMessage = { text: sendMessageCache, timestamp: Date.now(), uuid: Util.guid(), name: UserInfo.NAME, head: "imgs/head.png" };

            chatSo.sendMessage(serial.serial(MsgType.Type_Chat, UserInfo.ID, UserInfo.NAME, sendMessageCache))

            messageCache.push(newMessage);
            updateMsg += 1;
            setUpdateMsg(updateMsg);
        }
    }

    function onChatMessage(msg) {
        const newMessage = { text: msg.c, timestamp: Date.now(), uuid: Util.guid(), name: msg.sn, head: "imgs/head.png" };
        messageCache.push(newMessage);

        updateMsg += 1;
        setUpdateMsg(updateMsg);
    }

    return (
        <div>
            <div id='room_main' r={updateMsg} style={room_main_style}>
                {messageCache.map((msg, index) => (
                    <Message key={msg.uuid} message={msg} />
                ))}
            </div>
            <Input.Group compact className="input-group">
                <Input
                    style={{ width: 'calc(100% - 60px)' }}
                    placeholder="在此输入聊天"
                    onPressEnter={sendMessage}
                    onChange={(e)=>{  sendMessageCache = e.target.value}}
                />
                <Button icon={<SendOutlined />} onClick={sendMessage}/>
            </Input.Group>
        </div>
    );
};

export default ChatRoom;
