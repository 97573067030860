import React, { useRef, useEffect, useState, createRef } from 'react';
import './LivePage_Teacher.css'
import { WechatWorkOutlined, UsergroupAddOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Flex, Layout } from 'antd';
import Link from "./Link";
import ChatRoom from "./ChatRoom";
import {Button} from "antd";
import Util from "./Util";
import {RoomInfo, UserInfo} from "./Context";

const LivePage_Teacher = (props, ref) => {

    const [size, setSize] = useState({});
    const [chatCount, setChatCount] = useState(1);

    useEffect(()=>{

        const video = document.getElementById('head_video');

        // 访问用户媒体设备的媒体流
        navigator.mediaDevices.getUserMedia({ video: { deviceId: RoomInfo.Vid } })
            .then((stream) => {
                // 将媒体流设置为 video 元素的源
                video.srcObject = stream;
                console.error('访问摄像头OK');
                video.play();
            })
            .catch((err) => {
                console.error('访问摄像头Failed:', err);
            });

        // 监听浏览器窗口大小事件
        function handleResize() {
            const width = window.innerWidth;
            const height = window.innerHeight;
            console.log(`窗口大小: ${width} x ${height}`);

            setSize({height: height})
        }

        // 监听浏览器窗口的 resize 事件
        window.addEventListener('resize', handleResize);

        return ()=>{
            // 卸载浏览器窗口的 resize 事件
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    function getLabelText(i) {
        let text = ["聊天", "连麦"][i];
        if (i == 0) {
            text = `${text}(${chatCount})`
        }

        return text;
    }

    return (
        <div className="right_container">
            <video id="head_video" className="head"></video>
            <div id="tab_content_t">
                <Tabs id='tabs'
                      defaultActiveKey="2"
                      items={[WechatWorkOutlined, UsergroupAddOutlined].map((Icon, i) => {
                          const id = String(i + 1);
                          return {
                              key: id,
                              label: getLabelText(i),
                              children: [<ChatRoom si={size} countFn={setChatCount}/>, <Link />][i],
                              icon: <Icon />,
                              forceRender: true,
                          };
                      })}
                />
            </div>
        </div>
    )
}

export default LivePage_Teacher;

