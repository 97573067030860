
class SignalSocket
{
    constructor(url)
    {
        this.onmessage = undefined;

        this.openListener = []
        this.messageListener = []

        this.url = url;

        this.connect();
    }

    connect() {
        this.socket = new WebSocket(this.url);

        // Listen for messages
        this.socket.addEventListener("message", (event)=> {
            console.log("Message from server ", event.data);

            this.messageListener.forEach((listener, idx)=>{
                const msg = JSON.parse(event.data);
                listener(msg);
            });
        });

        // Connection opened
        this.socket.addEventListener("open", (event)=> {
            console.log("signal connected.");

            this.openListener.forEach((listener, idx)=>{
                listener();
            });
        });

        this.socket.addEventListener("error", function (event) {
            console.log("WebSocket error1: ", event);
        });

        this.socket.onerror = function(error) {
            console.error('WebSocket error2:', error);
        };

        this.socket.onclose = function(event) {
            console.log('Connection is closed');
            console.log('Close event:', event);
            console.log('Code:', event.code);
            console.log('Reason:', event.reason);
            console.log('WasClean:', event.wasClean);

            setTimeout(()=>{
                console.log("reconnect to server...")
                this.connect();
            }, 5000);
        };
    }

    addOpenListener(listener) {
        this.openListener.push(listener)
    }

    addMessageListener(listener) {
        this.messageListener.push(listener)
    }

    sendMessage(msg) {
        this.socket.send(msg);
        console.log("send msg: ", msg);
    }
}

export default SignalSocket;
