import React, { useRef, useEffect, useState, createRef } from 'react';
import Util from "./Util";
import SignalSocket from "./SignalSocket";
import MsgSerial, {MsgType} from "./MsgSerial";
import {NetSocket, UserInfo, UserStatus} from "./Context";

const serial = new MsgSerial();
const signalSo = new SignalSocket(Util.getSignalUrl());
signalSo.addOpenListener(()=>{
    signalSo.sendMessage(serial.serial(MsgType.Type_Info, UserInfo.ID, UserInfo.NAME, 'join room'));
});
NetSocket.SignalSo = signalSo;

const Link = (props, ref) => {

    let [linkChanged, setLinkChanged] = useState(1);

    useEffect(()=>{

        signalSo.addMessageListener((msg)=>{
            if (msg.id == UserInfo.ID) return;
        });

        if (UserInfo.ROLE == "student") {
            window.pushRTC(Util.getPushUrl(), "local_video");
            console.log("push url ", Util.getPushUrl())
        }

        let timerId = setTimeout(()=>{
            signalSo.sendMessage(serial.serial(MsgType.Type_Ping, UserInfo.ID, UserInfo.NAME, JSON.stringify(UserStatus)));
        }, 1000*10)

        return ()=>{

        }
    }, []);

    return (
        <div>
            <div id="local_video"></div>
        </div>
    )
}

export default Link;
