import React from 'react';
import './CustomProgressBar.css'; // 引入自定义样式文件

const CustomProgressBar = ({ percent }) => {
    return (
        <div className="progress-bar">
            <div className="progress" style={{ width: `${percent}%` }}></div>
        </div>
    );
};

export default CustomProgressBar;
