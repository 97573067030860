import React, { useRef, useEffect, useState, createRef } from 'react';
import './LivePage.css'
import { WechatWorkOutlined, UsergroupAddOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Flex, Layout } from 'antd';
import Link from "./Link";
import ChatRoom from "./ChatRoom";
import {Button} from "antd";
import Util from "./Util";
import {RoomInfo, UserInfo} from "./Context";

const { Header, Footer, Sider, Content } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 32,
    paddingInline: 48,
    lineHeight: '32px',
    backgroundColor: '#4096ff',
};
const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: 'gray',
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#333333',
    position: 'relative'
};

const siderContentStyle = {
    height: '100%',
    backgroundColor: 'gray',
    position: 'absolute'
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#4096ff',
    height: '32px',
    paddingTop: '0px'
};
const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
    height: '100%',
    // width: 'calc(50% - 8px)',
    // maxWidth: 'calc(50% - 8px)',
};

const videoContainerStyle = {
    width: '600px',
    height: "300px",
    position: "absolute",
    clipPath: 'polygon(0 0, 83.33333333333333% 0, 83.33333333333333% 100%, 0 100%)',
}

const videoContainerStyle_up = {
    width: '100%',
    height: 'calc(100% - 48px)',
    position: "relative",
    backgroundColor: "black"
}

const tabBarStyle = {
    color: "green"
}

// 创建一个 ResizeObserver 实例
const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
        const width = entry.contentRect.width;
        const height = entry.contentRect.height;
        console.log(`Width: ${width}, Height: ${height}`);

        let video_container = entry.target.video_target;

        if (width/height <= 1.777777777777778) {
            let extendWidth = 1.2 * width;
            let factor = extendWidth / 1920;

            let scaledHeight = 900 * factor;
            // 设置图像的宽度和高度

            video_container.style.width = `${extendWidth}px`;
            video_container.style.height = `${scaledHeight}px`;
            // 中心定位图像
            video_container.style.left = '0px';

            let top = height / 2 - scaledHeight / 2;
            video_container.style.top = `${top}px`;

        } else {
            let factor = height / 900;
            let scaledWidth = 1920 * factor;
            let sw = scaledWidth * 0.8333333333333333;

            video_container.style.width = `${scaledWidth}px`;
            video_container.style.height = `${height}px`;
            // 中心定位图像

            let left = width / 2 - sw / 2;
            video_container.style.left = `${left}px`;

            video_container.style.top = '0px';
        }

        // update size
        entry.target.updateSize();
    }
});

const Livepage = (props, ref) => {

    const [size, setSize] = useState({});
    const [chatCount, setChatCount] = useState(1);

    useEffect(()=>{
        let ele = document.getElementById('videoContainerStyle_up');
        let ele_video = document.getElementById('video-container');

        ele.video_target = ele_video;
        ele.updateSize = updateSize;

        resizeObserver.observe(ele);

        window.loadVideo(Util.getMainUrl(), 'player-container-id');

        const timer1 = setTimeout(()=>{
            processor.doLoad();
        }, 6000)

        const timer2 = setTimeout(()=>{

        }, 2000);

        return ()=>{
            resizeObserver.unobserve(ele);
            clearTimeout(timer1);
            clearTimeout(timer2);
        }
    }, []);

    function onFullScreen() {
        if (!document.fullscreenElement) {
            // 进入全屏
            document.documentElement.requestFullscreen();
        } else {
            // 退出全屏
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }

    function updateSize() {
        let tab = document.getElementById('tab_content');
        let content = tab.getElementsByClassName('ant-tabs-content-holder')[0];
        let cs = window.getComputedStyle(content);
        setSize(cs);
    }

    function getLabelText(i) {
        let text = ["聊天", "连麦"][i];
        if (i == 0) {
            text = `${text}(${chatCount})`
        }

        return text;
    }

    return (
        <Flex gap="middle" wrap style={{height:'100%'}}>
            <Layout style={layoutStyle}>
                <Header style={headerStyle}>Header</Header>
                <Layout>
                    <Content style={contentStyle}>
                        <div style={videoContainerStyle_up} id="videoContainerStyle_up">
                            <div style={videoContainerStyle} id="video-container">
                                <video id="player-container-id" preload="auto" playsInline webkit-playsinline="true"></video>
                            </div>
                        </div>
                        <div className="content-bottom-bar">xxxxxxxx</div>
                    </Content>
                    <Sider width="320px" style={siderStyle}>
                        <canvas id="c1" className="head"></canvas>

                        <div id="tab_content">
                            <Tabs id='tabs'
                                  defaultActiveKey="2"
                                  items={[WechatWorkOutlined, UsergroupAddOutlined].map((Icon, i) => {
                                    const id = String(i + 1);
                                    return {
                                        key: id,
                                        label: getLabelText(i),
                                        children: [<ChatRoom si={size} countFn={setChatCount}/>, <Link />][i],
                                        icon: <Icon />,
                                        forceRender: true,
                                    };
                                })}
                            />
                        </div>
                    </Sider>
                </Layout>
            </Layout>
        </Flex>
    )
}

export default Livepage;

var processor  = {};

processor.doLoad = function doLoad() {
    this.video = document.getElementById('player-container-id_html5_api');
    this.c1 = document.getElementById('c1');
    this.ctx1 = this.c1.getContext('2d');


    let self = this;
    self.timerCallback();
    this.video.addEventListener('play', function() {
        self.timerCallback();
    }, false);
}

processor.timerCallback = function timerCallback() {
    // if (this.video.paused || this.video.ended) {
    //     return;
    // }
    this.computeFrame();
    let self = this;
    setTimeout(function() {
        self.timerCallback();
    }, 50);
}

processor.computeFrame = function computeFrame() {
    this.ctx1.drawImage(this.video, 1600, 0, 320, 180, 0, 0, 320, 180);
};


