import React from 'react';

import './Message.css'

function Message({ message }) {
    return (
        <div>
            <div id='info_container'>
                <img src={message.head} id='head_img'/><span id='head_name'>{message.name}</span><small>{new Date(message.timestamp).toLocaleTimeString()}</small>
            </div>
            <p id='info_text'>{message.text}</p>
        </div>
    );
}

export default Message;
