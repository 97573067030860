// const url_s22 = "webrtc://play.xiaoyunduoyuan.com/live/s2"

let UserInfo = {
    ID: '',
    NAME: '',
    ROLE: 'student'
}

let RoomInfo = {
    ID: '',
    ChatCount: 0,
    Vid: '',
    Aid: ''
}

let UserStatus = {
    OpenCamera: false,
    OpenMicroPhone: false
}

let NetSocket = {
    SignalSo: undefined,
    ChatRoomSo: undefined
}

export { UserInfo, RoomInfo, UserStatus, NetSocket };
